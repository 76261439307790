
import {
    Box,
    Button,
    Typography,
} from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const BillsTableNarrow = ({bills, displayBillHandler}) =>
{
    return (
        <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            margin="1em"
        >
            <Typography variant="h5">Korábbi számlák:</Typography>
            {bills.map((bill) => (
                <Box key={bill.id} margin="1em">
                    <Box display="flex" >
                        <AccessTimeIcon style={{ color: "white" }} ></AccessTimeIcon>&nbsp;&nbsp;
                        <Typography>
                            {new Date(
                                Date.parse(bill.time)
                            ).toLocaleString("hu")}
                        </Typography>
                    </Box>
                    <Box display="flex">
                        <AttachMoneyIcon style={{ color: "white" }} ></AttachMoneyIcon>&nbsp;&nbsp;
                        <Typography>
                            {new Intl.NumberFormat("hu").format(
                                bill.amount
                            )}{" "}
                            Ft
                        </Typography>
                    </Box>
                    <Box margin=".5em">
                        <Button variant="contained" onClick={() => displayBillHandler(bill.id)}>Megnézem</Button>
                    </Box>
                </Box>
            ))}
        </Box>
    )
}