import React, { useId, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import api from "../network/Api";
import { loadUserData } from "../slices/LoginSlice";
import { ReadingsInput } from "../subcomponents/ReadingsInput";

export const DebugScreen = () => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const [readings, setReadings] = useState([]);
    const [apartment, setApartment] = useState(null);

    useEffect(() => {
        if (isLoggedIn) {
            setApartmentFromApi();
        }
    }, [isLoggedIn]);

    const setApartmentFromApi = () => {
        api.get("/users/me/apartment").then((response) => {
            let apartment = response.data;
            console.log("Received user apartment data:", apartment);
            setApartment(apartment);
        });
    };
    const saveReadingsToDebugApi = () => {
        api.post("/debug/readings", readings).then((response) => {
            console.log("Response:", response);
        });
    };

    return (
        <div>
            <Link to="/">Home</Link>
            <br />
            <Link to="/apartment-selector">Apartment Selector</Link>
            <br />
            <Link to="/bills">Bills</Link>
            <br />
            <Link to="/readings">Readings</Link>
            <br />
            <DebugLoginComponent></DebugLoginComponent>
            <br />
            <ReadingsInput
                readings={readings}
                setReadings={setReadings}
                apartment={apartment}
                previousReadings={{}}
                saveButtonHandler={saveReadingsToDebugApi}
                onMeterImageChangeHandler={() => () => {}}
                images={{}}
                uploadUrls={{"get": {}, "put": {}}}
                skipSavingCheck={true}
            />
        </div>
    );
};

const DebugLoginComponent = () => {
    const id = useId();
    const [input, setInput] = useState("");

    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const dispatch = useDispatch();

    const doFakeLogin = (e) => {
        api.put("/debug/login", {
            username: input,
        }).then((response) => {
            console.log("Debug login response:", response);
            dispatch(loadUserData());
        });
    };

    return (
        <div>
            Logged in: {isLoggedIn.toString()}
            <label htmlFor={id}>Username:</label>
            <input
                id={id}
                value={input}
                onInput={(e) => setInput(e.target.value)}
            />
            <button onClick={doFakeLogin}>Force login</button>
        </div>
    );
};
