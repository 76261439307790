
import {
    Button,
    Table,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    styled,
    tableCellClasses,
} from "@mui/material";

export const BillsTableWide = ({bills, displayBillHandler}) =>
{
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#181924",
            color: theme.palette.common.white,
            textAlign: "center",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            textAlign: "center",
            color: theme.palette.common.white,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.primary.other,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    return (
        <Table sx={{ minWidth: 300 }}>
            <TableHead>
                <TableRow>
                    <StyledTableCell align="center" colSpan="3">
                        Korábbi számlák
                    </StyledTableCell>
                </TableRow>
                <TableRow>
                    <StyledTableCell>Dátum:</StyledTableCell>
                    <StyledTableCell>Összeg:</StyledTableCell>
                    <StyledTableCell>Részletek:</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {bills.map((bill) => (
                    <StyledTableRow key={bill.id}>
                        <StyledTableCell>
                            {new Date(
                                Date.parse(bill.time)
                            ).toLocaleString("hu")}
                        </StyledTableCell>
                        <StyledTableCell>
                            {new Intl.NumberFormat("hu").format(
                                bill.amount
                            )}{" "}
                            Ft
                        </StyledTableCell>
                        <StyledTableCell>
                            <Button variant="contained" onClick={() => displayBillHandler(bill.id)}>Megnézem</Button>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    )
}