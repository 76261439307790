import React from 'react';

import {createTheme, ThemeProvider} from '@mui/material/styles';
import {HashRouter as Router, Route, Routes,} from "react-router-dom";

import './App.css';

import {BillsScreen} from "./components/screens/BillsScreen";
import {ReadingScreen} from "./components/screens/ReadingsScreen";
import {ApartmentSelectorScreen} from "./components/screens/ApartmentSelectorScreen";
import {LoginScreen} from "./components/screens/LoginScreen";
import {DebugScreen} from "./components/screens/DebugScreen";
import {OuterScreen} from "./components/screens/OuterScreen";
import {HomeScreen} from "./components/screens/HomeScreen";
import {ProtectedRoute} from "./components/ProtectedRoute";


const App = () => {

    const theme = createTheme({
        typography: {
            allVariants: {
                color: "#BFC7D5"
            },
        },
        palette: {
            mode: 'light',
            primary: {
                main: '#3d4156',
                other: "#3d4156",
            },
            secondary: {
                main: '#f50057',
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="/" Component={OuterScreen}>
                        <Route index Component={HomeScreen}/>
                        <Route path="/login" Component={LoginScreen}/>
                        <Route path="/apartment-selector" element={<ProtectedRoute><ApartmentSelectorScreen /></ProtectedRoute>}/>
                        <Route path="/bills" element={<ProtectedRoute><BillsScreen /></ProtectedRoute>}/>
                        <Route path="/readings" element={<ProtectedRoute><ReadingScreen /></ProtectedRoute>} />
                        <Route path="/debug" Component={DebugScreen}/>
                    </Route>
                </Routes>
            </Router>
        </ThemeProvider>
    )
}

export default App;
