import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import api, {loadApartmentFromApi} from "../network/Api";
import { ReadingsInput } from "../subcomponents/ReadingsInput";
import { ReadingsConfirmationModal } from "../subcomponents/ReadingsConfirmationModal";
import { ReadingsTotalFeeModal } from "../subcomponents/ReadingsTotalFeeModal";
import {uploadToS3} from "../network/ImageUpload";

const MAX_IMAGE_SIZE = 10000000

export const ReadingScreen = () => {
    const [images, setImages] = useState({});
    const [apartment, setApartment] = useState(null);
    const [previousReadings, setPreviousReadings] = useState({});
    const [readings, setReadings] = useState([]);
    const [uploadUrls, setUploadUrls] = useState({});
    const [totalFee, setTotalFee] = useState(0);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [totalFeeModalOpen, setTotalFeeModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        loadApartmentFromApi(setApartment);
        setPreviousReadingsFromApi();
        setUploadUrlsFromApi();
    }, []);


    const setPreviousReadingsFromApi = () => {
        api.get("/users/me/apartment/readings/previous").then((response) => {
            let readings = response.data;
            console.log("Received previous readings:", readings);
            let readingsWithMeterKey = {};
            readings.forEach((element) => {
                readingsWithMeterKey[element.meter_id] = element.reading_value;
            });
            setPreviousReadings(readingsWithMeterKey);
        });
    };

    const setUploadUrlsFromApi = () => {
        api.get("/users/me/apartment/upload_urls").then((response) => {
            let uploadUrls = response.data;
            console.log("Received upload urls:", uploadUrls);
            setUploadUrls(uploadUrls);
        });
    }

    const onMeterImageChangeHandler = (meter_id) => (e) => {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            storeImageInState(meter_id, files[0]);
    }

    const storeImageInState = (meter_id, file) => {
        let reader = new FileReader()
        reader.onload = (e) => {
            let imageLength = e.target.result.length;
            console.log('Image length: ', imageLength);
            if (!e.target.result.includes('data:image/jpeg')) {
                return alert('Hibás fájl formátum - csak JPG engedélyezett');
            }
            if (imageLength > MAX_IMAGE_SIZE) {
                return alert('Túl nagy fájlméret.');
            }
            let updatedImages = {...images};
            updatedImages[meter_id] = e.target.result;
            setImages(updatedImages);
        }
        reader.readAsDataURL(file);
    }

    const saveReadingsToApi = () => {
        for (const meter_id in images) {
            console.log("Saving image for meter:", meter_id);
            uploadToS3(images[meter_id], uploadUrls['put'][meter_id]);
        }
        console.log("Posting the following readings:", readings);
        api.post("/users/me/apartment/readings", readings).then((response) => {
            setConfirmationModalOpen(false);
            setTotalFee(response.data.amount);
            setTotalFeeModalOpen(true);
        });
    };

    const handleConfirmationModalClose = () => {
        setConfirmationModalOpen(false);
    };

    const handleTotalFeeClose = () => {
        setTotalFeeModalOpen(false);
        navigate("/");
    };

    return (
        <>
            <ReadingsInput
                readings={readings}
                setReadings={setReadings}
                apartment={apartment}
                previousReadings={previousReadings}
                saveButtonHandler={() => setConfirmationModalOpen(true)}
                onMeterImageChangeHandler={onMeterImageChangeHandler}
                images={images}
                uploadUrls={uploadUrls}
            />
            <ReadingsConfirmationModal
                confirmationModal={confirmationModalOpen}
                handleConfirmationClose={handleConfirmationModalClose}
                readings={readings}
                apartment={apartment}
                previousReadings={previousReadings}
                setConfirmationModal={setConfirmationModalOpen}
                saveReadingsToApi={saveReadingsToApi}
                images={images}
            />
            <ReadingsTotalFeeModal
                totalFeeModal={totalFeeModalOpen}
                handleTotalFeeClose={handleTotalFeeClose}
                totalFee={totalFee}
            />
        </>
    );
};
