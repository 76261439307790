import axios from "axios";

let api = axios.create({
    baseURL: "/api",
    timeout: 5000,
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log("An error was encountered:", error);
    }
);

export const loadBillsFromApi = (setBills) => {
    api.get("/users/me/bills").then((response) => {
        if (!response) {
            console.log("Retrieving user data returned an error");
            return;
        }
        setBills(response.data);
    });
}

export const loadApartmentFromApi = (setApartment) => {
    api.get("/users/me/apartment").then((response) => {
        let apartment = response.data;
        console.log("Received user apartment data:", apartment);
        setApartment(apartment);
    });
};

export default api;
