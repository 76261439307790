import { Box, Button, Input, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import FlashOnIcon from "@mui/icons-material/FlashOn";
import PropaneTankIcon from "@mui/icons-material/PropaneTank";
import WaterDropIcon from "@mui/icons-material/WaterDrop";


export const ReadingsInput = ({
    apartment,
    previousReadings,
    saveButtonHandler,
    readings,
    setReadings,
    onMeterImageChangeHandler,
    images,
    uploadUrls,
    skipSavingCheck,
}) => {

    const isSavingAllowed = () => {
        console.log("Is saving allowed called. (apartment, readings, images)", apartment, readings, images);

        if (skipSavingCheck) {
            console.log("Saving check is turned off");
            return true;
        }

        if (! (apartment && readings && images && previousReadings)) {
            return false;
        }

        let numOfMeters = apartment.meters.length;
        let numOfReadings = readings.length;
        let numOfImages = Object.getOwnPropertyNames(images).length
        let numOfPreviousReadingsItemCount = Object.getOwnPropertyNames(previousReadings).length;

        if (numOfMeters === numOfReadings)
        {
            if (numOfPreviousReadingsItemCount > 0) {
                for (let reading of readings) {
                    if (!reading) {
                        console.log("There is a falsy reading value.");
                        return false;
                    }
                    let previousValue = previousReadings[reading.meter_id];
                    let currentValue = reading.reading_value;
                    if (previousValue > currentValue) {
                        console.log("The previous value is higher than the current one.");
                        return false;
                    }
                }
            }
            if (numOfMeters !== numOfImages) {
                console.log("The number of meters isn't the same as the number of images.", numOfMeters, numOfImages);
                return false;
            }
            return true;
        }
        return false;
    };

    const getCategoryIcon = (categoryName) => {
        switch (categoryName) {
            case "ELECTRICITY":
                return <FlashOnIcon style={{ color: "yellow" }} />;
            case "WATER":
                return <WaterDropIcon style={{ color: "blue" }} />;
            case "GAS":
                return <PropaneTankIcon style={{ color: "green" }} />;
            default:
                return <></>;
        }
    };
    return (
        <Grid container direction="column" spacing="1em" alignItems="center">
            <Grid>
                <Typography>
                    Mérőórák feltöltése a {apartment && apartment.address}{" "}
                    címre.

                    Minden órát ki kell tölteni, és minden képet fel kell tölteni, akkor is ha nem változott a mért érték.
                    Amennyiben a mostani és előző havi érték megegyezik, törölje ki az értéket, és írja be újra.
                </Typography>
                <br />
                <br />
            </Grid>
            <Grid>
                {apartment &&
                    apartment.meters.map((meter, index) => (
                        <Box key={index}>
                        <Grid
                            container
                            spacing="1em"
                            alignItems="center"
                            justifyContent="space-between"
                            columns={12}
                        >
                            <Grid xs="2" sm="auto">
                                <Grid container direction="row" alignItems="center">
                                    <Grid>{getCategoryIcon(meter.meter_type.category)}</Grid>
                                    <Grid><Typography>{meter.meter_type.name}</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm="auto">
                                <Typography>{meter.meter_number}</Typography>
                            </Grid>
                            <Grid xs={12} sm="auto">
                                <TextField
                                    variant="outlined"
                                    label={`Korábbi: ${previousReadings[meter.id]}`}
                                    sx={{ input: { color: "white" } }}
                                    key={index}
                                    type="number"
                                    placeholder={`Óra: ${meter.meter_number}`}
                                    value={
                                        readings[index]
                                            ? readings[index].reading_value
                                            : previousReadings[meter.id]
                                            ? previousReadings[meter.id]
                                            : -1
                                    }
                                    onChange={(e) => {
                                        let newReadings = [...readings];
                                        let reading = {
                                            meter_id: meter.id,
                                            picture_url: uploadUrls['get'][meter.id],
                                            reading_value: e.target.value !== "" ? Number(e.target.value): "",
                                        };
                                        newReadings[index] = reading;
                                        setReadings(newReadings);
                                    }}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Input sx={{color:"white"}} id="meterInput" type="file" accept="image/*" capture="camera" onChange={onMeterImageChangeHandler(meter.id)}></Input>
                            </Grid>
                        </Grid>
                        <br />
                        <br />
                        <br />
                        </Box>
                    ))}
            </Grid>
            <Grid>
                <Button
                    variant="contained"
                    onClick={saveButtonHandler}
                    disabled={!isSavingAllowed()}
                >
                    Mentés
                </Button>
            </Grid>
        </Grid>
    );
};
