import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const ReadingsConfirmationModal = ({
    confirmationModal,
    handleConfirmationClose,
    readings,
    apartment,
    previousReadings,
    setConfirmationModal,
    saveReadingsToApi,
    images,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={confirmationModal}
            onClose={handleConfirmationClose}
        >
            <DialogTitle>Mért eredmények mentése</DialogTitle>
            <DialogContent>
                <Grid container direction="column" spacing="2em">
                    <Grid>
                        Az Ön által megadott mérőóra adatok a következőek:
                    </Grid>
                    <Grid>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Óra szám:</TableCell>
                                    <TableCell>Állás:</TableCell>
                                    <TableCell>Fogyasztás:</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {readings.map((reading, index) => {
                                    if (!reading) {
                                        return <></>;
                                    }
                                    return (
                                        <React.Fragment key={apartment.meters[index].id}>
                                            <TableRow>
                                                <TableCell>
                                                    {
                                                        apartment.meters[index]
                                                            .meter_number
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {reading.reading_value}
                                                </TableCell>
                                                <TableCell>
                                                    {reading.reading_value - previousReadings[apartment.meters[index].id]}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    <img className="thumbnail" src={images[apartment.meters[index].id]} alt="" width="90%"></img>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid>Beküldi?</Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => setConfirmationModal(false)}>
                    Nem
                </Button>
                <Button onClick={saveReadingsToApi} autoFocus>
                    Igen
                </Button>
            </DialogActions>
        </Dialog>
    );
};
