import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

    if (!isLoggedIn) {
        console.log("User is not logged in, redirecting to home...");
        return <Navigate to="/" />;
    }
    return children;
};
