import React from "react";

import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export const LoginScreen = () => {
    const login = () => {
        window.location.href = "/api/auth/redirect-to-cognito-login";
    };

    return (
        <Grid container direction="column" alignItems="center" rowSpacing="1em">
            <Grid>
                <Typography>
                    Az alkalmazás használatához kérlek jelentkezz be:
                </Typography>
            </Grid>
            <Grid>
                <Button variant="contained" onClick={login}>
                    Bejelentkezés
                </Button>
            </Grid>
        </Grid>
    );
};
