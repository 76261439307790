import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { BillsTableWide } from "../subcomponents/BillsTableWide";
import { BillsTableNarrow } from "../subcomponents/BillsTableNarrow";

import api, {loadApartmentFromApi, loadBillsFromApi} from "../network/Api";

export const BillsScreen = () => {
    const [bills, setBills] = useState([]);
    const [apartment, setApartment] = useState(null);
    const [billDetailsModal, setBillDetailsModal] = useState(false);
    const [selectedBill, setSelectedBill] = useState(null);
    const theme = useTheme();
    const largeResolution = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        loadBillsFromApi(setBills);
        loadApartmentFromApi(setApartment);
    }, []);

    const handleBillDetailsClose = () => {
        setBillDetailsModal(false);
    };

    const displayBill = (billId) => {
        api.get("/users/me/bills/" + billId).then((response) => {
            setSelectedBill(response.data);
        });
        setBillDetailsModal(true);
    };

    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding="1em"
            >
                <Typography>
                    {apartment && apartment.address}
                </Typography>
                <br /><br />
                <Button component={Link} variant="contained" to="/readings">
                    Új mérés diktálása
                </Button>
            </Box>
            {bills.length === 0 && (
                <Typography sx={{textAlign: "center"}}>
                    Önnek jelenleg nincsenek felvitt számlái.
                </Typography>
            )}
            {bills.length > 0 && largeResolution && (
                <BillsTableWide bills={bills} displayBillHandler={displayBill}></BillsTableWide>
            )}
            {bills.length > 0 && !largeResolution && (
                <BillsTableNarrow bills={bills} displayBillHandler={displayBill}></BillsTableNarrow>
            )}

            {selectedBill && (
                <Dialog
                    open={billDetailsModal}
                    onClose={handleBillDetailsClose}
                >
                    <DialogTitle color="black">Számla részletek</DialogTitle>
                    <DialogContent>
                        <Grid container direction="column" spacing="2em">
                            <Grid>
                                <Typography color="black">
                                    A számla összege: {selectedBill.amount} Ft
                                </Typography>
                            </Grid>
                            <Grid container direction="column" spacing="0.5em">
                                {JSON.parse(selectedBill.description).map(
                                    (item, index) => (
                                        <Grid key={index}>
                                            {item[0]}: {item[1]} Ft
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBillDetailsClose} autoFocus>
                            Bezár
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
