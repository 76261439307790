import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";

import HouseIcon from "@mui/icons-material/House";
import {AppBar, Box, Button , Container, Toolbar, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import api from "../network/Api";
import { emptyUserData, loadUserData, logoutUser } from "../slices/LoginSlice";

export const OuterScreen = () => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const userFullName = useSelector((state) => state.login.userFullName);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        api.get("/users/me/login_status").then((response) => {
            let loginStatus = response.data.login_status;
            console.log("Retrieved login status:", loginStatus);
            if (loginStatus) {
                dispatch(loadUserData());
            } else {
                dispatch(emptyUserData());
            }
        });
    });

    const logout = () => {
        console.log("Logout user");
        dispatch(logoutUser());
        navigate("/");
    };

    return (
        <Container className="content" maxWidth="sm">
            <AppBar position="static" >
                <Toolbar>
                    <Grid container alignItems="center" sx={{"width": "100%"}}>
                        <Grid xs={1} sm={1}>
                            <HouseIcon></HouseIcon>
                        </Grid>
                        <Grid xs={11} sm={4}>
                            <Typography
                                component={Link}
                                to="/"
                                variant="h6"
                                sx={{ textDecoration: "none" }}
                            >
                                &nbsp; Albérlet Kezelő
                            </Typography>
                        </Grid>
                        {isLoggedIn && (<>
                        <Grid xs={6} sm={3}>
                            <Typography display="inline">
                                {userFullName}
                            </Typography>
                        </Grid>
                        <Grid xs={6} sm={4} textAlign="right">
                            <Button
                                variant="contained"
                                onClick={logout}
                            >
                                Kijelentkezés
                            </Button>
                        </Grid></>
                        )}
                        {!isLoggedIn && (
                        <Grid>
                            <Typography>Nincs bejelentkezve</Typography>
                        </Grid>
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>
            <Box sx={{ padding: "1em" }}>
                <Outlet />
            </Box>
        </Container>
    );
};
