import { createSlice } from "@reduxjs/toolkit";
import api from "../network/Api";

export const loginSlice = createSlice({
    name: "login",
    initialState: {
        isLoggedIn: false,
        userName: null,
        userFullName: null,
        apartmentId: null,
    },
    reducers: {
        setUserData: (state, action) => {
            state.isLoggedIn = true;
            state.userName = action.payload.userName;
            state.userFullName = action.payload.userFullName;
            state.apartmentId = action.payload.apartmentId;
        },
        emptyUserData: (state) => {
            state.isLoggedIn = false;
            state.userName = null;
            state.userFullName = null;
            state.apartmentId = null;
        },
    },
});

export function loadUserData() {
    return async function loadUserDataThunk(dispatch, getState) {
        api.get("/users/me").then((response) => {
            if (!response) {
                console.log("Retrieving user data returned an error");
                return;
            }
            console.log("Retrieved user data:", response.data);
            dispatch(
                setUserData({
                    userName: response.data.username,
                    userFullName: response.data.name,
                    apartmentId: response.data.apartment_id,
                })
            );
        });
    };
}

export function logoutUser() {
    return async function logoutUserThunk(dispatch, getState) {
        dispatch(emptyUserData());
        window.location.href = "/api/auth/logout";
    };
}

export const { setUserData, emptyUserData } = loginSlice.actions;
export default loginSlice.reducer;
