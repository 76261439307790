import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const ReadingsTotalFeeModal = ({
    totalFeeModal,
    handleTotalFeeClose,
    totalFee,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog
            fullScreen={fullScreen}
            open={totalFeeModal}
            onClose={handleTotalFeeClose}
        >
            <DialogTitle>Havi számla</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Ebben a hónapban fizetendő: {totalFee} Ft
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleTotalFeeClose} autoFocus>
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    );
};
