import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, MenuItem, Select, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import api from "../network/Api";
import { loadUserData } from "../slices/LoginSlice";

export const ApartmentSelectorScreen = () => {
    const [apartments, setApartments] = useState([]);
    const [selectedApartment, setSelectedApartment] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        api.get("/apartments/").then((response) => {
            let apartments = response.data;
            setSelectedApartment(apartments[0].id);
            setApartments(apartments);
        });
    }, []);

    const saveApartment = () => {
        api.put("/users/me/apartment", {
            apartment_id: selectedApartment,
        }).then((response) => {
            console.log("Save apartment response:", response);
            dispatch(loadUserData());
            navigate("/");
        });
    };

    const setSelectedApartmentHandler = (e) => {
        let apartment_id = e.target.value;
        console.log("Apartment id:", apartment_id);
        setSelectedApartment(apartment_id);
    };

    return (
        <Grid container direction="column" rowSpacing="2em" alignItems="center">
            <Grid>
                <Typography variant="h5">Válassza ki a címét:</Typography>
            </Grid>
            <Grid>
                <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid>
                        <Select
                            value={selectedApartment}
                            onChange={setSelectedApartmentHandler}
                            sx={{ minWidth: "100", color: "white" }}
                        >
                            {apartments.map((apartment) => (
                                <MenuItem
                                    key={apartment.id}
                                    value={apartment.id}
                                    sx={{ color: "black" }}
                                >
                                    {apartment.address}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid>
                        <Button variant="contained" onClick={saveApartment}>
                            Mentés
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
