import React from "react";
import { useSelector } from "react-redux";

import { ApartmentSelectorScreen } from "./ApartmentSelectorScreen";
import { BillsScreen } from "./BillsScreen";
import { LoginScreen } from "./LoginScreen";

export const HomeScreen = () => {
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    const apartmentId = useSelector((state) => state.login.apartmentId);

    if (isLoggedIn && apartmentId) {
        return <BillsScreen />;
    } else if (isLoggedIn) {
        return <ApartmentSelectorScreen />;
    } else {
        return <LoginScreen />;
    }
};
