export const uploadToS3 = async (image, s3Url) => {
    let binary = atob(image.split(',')[1])
    let array = []
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
    }
    let blobData = new Blob([new Uint8Array(array)], {type: 'image/jpeg'})
    console.log('Uploading to: ', s3Url)
    const result = await fetch(s3Url, {
        method: 'PUT',
        body: blobData,
        headers: {
            "Content-Type": "image/jpeg",
            "x-amz-acl": "public-read"
        },
    })
    console.log('Image upload result: ', result)
}
